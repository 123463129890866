// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://mansurcargo.kz:3001',
    
    nameCargo: 'Mansur Cargo'
  };
  
  
export default config;
  